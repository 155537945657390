import _ from 'lodash';
import jwt_decode from 'jwt-decode';
import { ROUTERS } from '@constants';
import { history } from '@/store';
import { pushNotification, pushAlert } from './Widget.utils';

// Declare constants

// declare avaiable screens
// const AVAIABLE_ROUTES = [ROUTER.INITIALAZATION, ROUTER.HOME];

// Check network connection
const checkNetworkConnection = () => {
  return navigator.onLine;
};

// Redirect screen
const redirect = (location: string) => {
  const findLocation = _.find(ROUTERS, (route) => route === location);
  if (!findLocation) return history.push(ROUTERS.FORBIDEN);
  return history.push(location);
};

// Check life of token
const checkTokenLifeTime = (token: string | null) => {
  if (!token) {
    pushAlert({
      message: 'Please login to continue...',
      type: 'warning',
    });
    return false;
  }
  const decodedToken: any = jwt_decode(token);
  const dateNow = new Date();
  if (decodedToken.exp < Math.floor(dateNow.getTime() / 1000)) {
    pushNotification({
      message: 'Your token has been expired!',
      type: 'error',
    });
    return false;
  }
  return true;
};


const getFileFromURL = async (url: string, filename: string) => {
  const resolveUrl = url.replace(/\\/g, '/').replace('src/storage/file', '');
  const blob = await fetch(resolveUrl, {}).then((r) => r.blob());
  const file = new File([blob], filename);
  return file;
};

const getJsonFromURL = async (url: string) => {
  const resolveUrl = url.replace(/\\/g, '/').replace('src/storage/file', '');
  console.log("GETTING FROM URL:", resolveUrl)
  const json = await fetch(resolveUrl, {}).then((r) => r.json());
  return json;
};

// Return app run mode
const checkAppMode = (modeType?: string) => {
  if (modeType) return import.meta.env.MODE === modeType;
  return import.meta.env.MODE;
};

// Sleep for delay
const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

export {
  checkNetworkConnection,
  redirect,
  checkTokenLifeTime,
  checkAppMode,
  sleep,
  getFileFromURL,
  getJsonFromURL
};
