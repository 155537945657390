import {
  Polygon,
  MultiPolygon,
  LineString,
  MultiLineString,
  Point,
} from 'geojson';
import _ from 'lodash';
import * as turf from '@turf/turf';
import {
  S3,
  GetObjectCommand,
  GetObjectCommandOutput,
} from '@aws-sdk/client-s3';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { ILayersSettings } from '@interfaces/Menu.interface';
import {
  IGeoJsonObjectCollection,
  IGISFeature,
  IGeojsonGroups,
} from '@interfaces/GIS.interface';
import { IDownloadOption } from '@interfaces/Download.interface';
import { GIS_TYPES } from '@constants';
import Utils from '@/Utils';
const proj4 = window.proj4;
const { CRS_CODES } = GIS_TYPES;
type CRS_CONVERT_CASES = 'default' | '6870' | '32634';

const s3Client = new S3({
  credentials: {
    accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
    secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
  },
  region: import.meta.env.VITE_AWS_REGION,
});

const getCenterOfFeature = (
  geometry: LineString | MultiLineString | MultiPolygon | Polygon | Point
) => {
  let feature = null;
  switch (geometry.type) {
    case 'MultiPolygon':
      feature = turf.centerOfMass(turf.multiPolygon(geometry.coordinates));
      break;
    case 'Polygon':
      feature = turf.centerOfMass(turf.polygon(geometry.coordinates));
      break;
    case 'LineString':
      feature = turf.centerOfMass(turf.lineString(geometry.coordinates));
      break;
    case 'MultiLineString':
      feature = turf.centerOfMass(turf.multiLineString(geometry.coordinates));
      break;
    default:
      return geometry.coordinates;
  }
  return feature.geometry.coordinates;
};

const generateRandomColor = (size: number) => {
  const genRanHex = [...Array(size)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join('');
  return `#${genRanHex}`;
};

const generateGeojsonOptions = (groups: any): IGeojsonGroups[] => {
  const results: IGeojsonGroups[] = [];
  _.forEach(groups, (group) => {
    const layers = _.get(group, 'geojson');
    const name = _.get(group, 'name');
    const id = _.get(group, 'id');
    const tmp: ILayersSettings[] = [];
    if (!_.isEmpty(layers)) {
      _.forEach(layers, (layer) => {
        const extraData = layer?.extraData
          ? JSON.parse(layer.extraData)
          : undefined;
        const randomColor = generateRandomColor(6);
        const layerName = layer?.file?.nameOriginal.replace('.geojson', '');
        const parentLayer = {
          layerName,
          isChecked: false,
          color:
            _.isEmpty(layer?.sublayer || []) && !extraData?.color
              ? randomColor
              : extraData?.color,
          extraData,
        };
        const sublayers = _.map(layer?.sublayer || [], (l) => ({
          ...l,
          isChecked: false,
        }));
        tmp.push({
          id: layer.id,
          key: Math.random(),
          parentLayer,
          subLayers: _.orderBy(sublayers, 'name', 'asc'),
          url: layer?.file?.path,
        });
      });
    }
    if (tmp.length > 0)
      results.push({
        id,
        name,
        layers: tmp,
      });
  });
  return results;
};

const downloadGeojson = (
  url: string
): Promise<IGeoJsonObjectCollection | null> => {
  return new Promise(async (resolve) => {
    const key: string = _.last(url.split('/')) || '';
    try {
      const command = new GetObjectCommand({
        Bucket: import.meta.env.VITE_AWS_PUBLIC_BUCKET_NAME,
        Key: `geojsons/${key}`,
        ResponseContentType: 'application/json',
      });

      const response = await s3Client.send(command);
      if (response.Body) {
        const geojson = await new Response(response.Body as BodyInit).json();
        resolve(geojson);
      }
      resolve(null);
    } catch (err) {
      resolve(null);
    }
  });
};

const _defineProj4CRS = (crs: CRS_CONVERT_CASES) => {
  switch (crs) {
    case '6870': {
      proj4.defs(CRS_CODES.AL_CODE.CODE, CRS_CODES.AL_CODE.PROPERTIES);
      return proj4.Proj(CRS_CODES.AL_CODE.CODE);
    }
    case '32634': {
      proj4.defs(
        CRS_CODES.WGS84_32634_CODE.CODE,
        CRS_CODES.WGS84_32634_CODE.PROPERTIES
      );
      return proj4.Proj(CRS_CODES.WGS84_32634_CODE.CODE);
    }
    default:
      return proj4.Proj(CRS_CODES.DEFAULT_CODE.CODE);
  }
};

const transformCoordinates = (
  coordinates: number[],
  crs: CRS_CONVERT_CASES
) => {
  proj4.defs(CRS_CODES.DEFAULT_CODE.CODE, CRS_CODES.DEFAULT_CODE.PROPERTIES);
  const source = _defineProj4CRS(crs);
  const dest = proj4.Proj(CRS_CODES.DEFAULT_CODE.CODE);
  const result = proj4.transform(source, dest, coordinates);
  return [result.x, result.y];
};

const _convertPointCoordinates = (geometry: Point, crs: CRS_CONVERT_CASES) => {
  const coordinates = transformCoordinates(geometry.coordinates, crs);
  return { ...geometry, coordinates };
};

const _convertMPolygonCoordinates = (
  geometry: MultiPolygon,
  crs: CRS_CONVERT_CASES
) => {
  const { coordinates } = geometry;
  const result = _.map(coordinates, (p1) =>
    _.map(p1, (p2) => _.map(p2, (p3) => transformCoordinates(p3, crs)))
  );
  return { ...geometry, coordinates: result };
};

const _convertMLineStringCoordinates = (
  geometry: MultiLineString,
  crs: CRS_CONVERT_CASES
) => {
  const { coordinates } = geometry;
  const result = _.map(coordinates, (p1) =>
    _.map(p1, (p2) => transformCoordinates(p2, crs))
  );
  return { ...geometry, coordinates: result };
};

const _convertPolygonCoordinates = (
  geometry: Polygon,
  crs: CRS_CONVERT_CASES
) => {
  const { coordinates } = geometry;
  const result = _.map(coordinates, (p1) =>
    _.map(p1, (p2) => transformCoordinates(p2, crs))
  );
  return { ...geometry, coordinates: result };
};

const _convertLineStringCoordinates = (
  geometry: LineString,
  crs: CRS_CONVERT_CASES
) => {
  const { coordinates } = geometry;
  const result = _.map(coordinates, (p1) => transformCoordinates(p1, crs));
  return { ...geometry, coordinates: result };
};

const convertGeometry = (
  geometry: LineString | MultiLineString | MultiPolygon | Polygon | Point,
  crs: CRS_CONVERT_CASES
): LineString | MultiLineString | MultiPolygon | Polygon | Point => {
  switch (geometry.type) {
    case 'MultiPolygon':
      return _convertMPolygonCoordinates(geometry, crs);
    case 'Polygon':
      return _convertPolygonCoordinates(geometry, crs);
    case 'LineString':
      return _convertLineStringCoordinates(geometry, crs);
    case 'MultiLineString':
      return _convertMLineStringCoordinates(geometry, crs);
    default:
      return _convertPointCoordinates(geometry, crs);
  }
};

const convertToCRS84 = (
  geojson: IGeoJsonObjectCollection,
  id: string
): Promise<IGeoJsonObjectCollection> => {
  return new Promise((resolve) => {
    const getCRS = _.get(geojson.crs.properties, 'name');
    if (!getCRS) resolve({ ...geojson, id });
    const isCRS6870 = getCRS.includes('6870');
    const isCRS32634 = getCRS.includes('32634');
    let crs: CRS_CONVERT_CASES = 'default';
    if (isCRS6870) crs = '6870';
    else if (isCRS32634) crs = '32634';
    const convertedFeatures: IGISFeature[] = [];
    const { features } = geojson;
    _.map(features, (feature) => {
      const geometry = convertGeometry(feature.geometry, crs);
      const convertedFeature = { ...feature, geometry };
      convertedFeatures.push(convertedFeature);
    });
    resolve({ ...geojson, id, features: convertedFeatures });
  });
};

const downloadGeojsonLocal = (
  path: string
): Promise<IGeoJsonObjectCollection | null> => {
  return new Promise(async (resolve) => {
    try {
      console.log("Download URL:",path)
      const response = await Utils.getJsonFromURL(path);
      resolve(response);
    } catch (err) {
      resolve(null);
    }
  });
};

const generateDownloadOptions = (geojsons: any): IDownloadOption[] => {
  const results: IDownloadOption[] = _.map(geojsons, (geojson) => {
    const id = _.get(geojson, 'id');
    const fileName = _.get(geojson, 'file.nameOriginal');
    const name = fileName.replace('.geojson', '');
    const path = _.get(geojson, 'file.path');
    const group = _.get(geojson, 'groupId.name') || 'uncategorized';
    return {
      id,
      fileName,
      name,
      path,
      group,
    };
  });
  return results;
};

interface IDownloadParameters {
  geojsons: IDownloadOption[];
  totalStep: number;
  callbackProgress(message: string): void;
  callbackEndProcess(status: boolean): void;
  callbackRunProcess(percent: number): void;
}

interface IDownloadProcess {
  step: number;
  totalStep: number;
  callbackRunProcess(percent: number): void;
}

const asStream = (response: GetObjectCommandOutput) => {
  return response.Body as ReadableStream;
};

const asBlob = async (response: GetObjectCommandOutput) => {
  return await new Response(asStream(response)).blob();
};

const calculatePercent = (params: IDownloadProcess): Promise<void> => {
  const { step, totalStep, callbackRunProcess } = params;
  return new Promise((resolve) => {
    let count = 0;
    let currentStep = step;
    const process = setInterval(() => {
      if (count <= 1) {
        currentStep += 0.01;
        count += 0.01;
        const newPerent = ((currentStep / totalStep) * 100).toFixed(0);
        callbackRunProcess(parseFloat(newPerent));
      }
      if (count > 1) {
        clearInterval(process);
        resolve();
      }
    }, 20);
  });
};

const downloadMultipleGeojsons = async (
  params: IDownloadParameters
): Promise<void> => {
  return new Promise(async () => {
    const {
      geojsons,
      totalStep,
      callbackProgress,
      callbackEndProcess,
      callbackRunProcess,
    } = params;
    let step = 0;
    callbackProgress(`Start downloading...`);
    setTimeout(() => {}, 1000);
    const zip = new JSZip();
    for (const geojson of geojsons) {
      const { fileName, path } = geojson;
      const key: string = _.last(path.split('/')) || '';
      callbackProgress(`Start downloading ${fileName}!`);
      try {
        const command = new GetObjectCommand({
          Bucket: import.meta.env.VITE_AWS_PUBLIC_BUCKET_NAME,
          Key: `geojsons/${key}`,
          ResponseContentType: 'application/json',
        });
        const response = await s3Client.send(command);
        await calculatePercent({ step, totalStep, callbackRunProcess });
        step += 1;
        callbackProgress(`Download ${fileName} success!`);
        if (response.Body) {
          const file = await asBlob(response);
          zip.file(fileName, file, { base64: true });
          await calculatePercent({ step, totalStep, callbackRunProcess });
          step += 1;
        }
      } catch (err) {
        callbackProgress(`Download ${fileName} fail!`);
        await calculatePercent({ step, totalStep, callbackRunProcess });
        step += 2;
      }
    }
    if (step === totalStep) {
      const zipName = `geojson_${new Date().getTime()}.zip`;
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, zipName);
        callbackEndProcess(true);
      });
    }
  });
};

export default {
  getCenterOfFeature,
  generateGeojsonOptions,
  downloadGeojson,
  convertToCRS84,
  generateDownloadOptions,
  downloadMultipleGeojsons,
  downloadGeojsonLocal
};
